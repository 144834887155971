/**
 * 雇主，订单状态
 */
export const ORDER_STATE = {
  CANCELED:           { label: '已取消', value: -1, cssClass: 'info', show: true },
  WAITING_PAY:        { label: '待支付', value: 1, cssClass: 'warn', show: true },
  PAID:               { label: '已支付', value: 2, cssClass: 'warn', show: true },
  WORKING:            { label: '工作中', value: 3, cssClass: 'success', show: true },
  WAITING_CHECK:      { label: '待验收', value: 4, cssClass: 'warn', show: true }, // 验收完成，待评价
  CHECK_PASS:         { label: '待评价', value: 5, cssClass: 'warn', show: true },
  EVALUATED:          { label: '已评价', value: 6, cssClass: 'success', show: true },
  IN_APPEAL:          { label: '申诉中', value: 7, cssClass: 'warn', show: true },
  APPEAL_SUCCEED:     { label: '申诉成功', value: 8, cssClass: 'success', show: true }, // 申诉成功、失败，是对于下单用户，相对的服务商申诉状态相反
  EXPIRED:            { label: '已超时', value: 9, cssClass: 'danger', show: false }, // 已支付，未开始工作，退款
  REFUNDING:          { label: '退款中', value: 10, cssClass: 'success', show: true },
  REFUNDED:           { label: '已退款', value: 11, cssClass: 'success', show: true },
  REFUNDED_ERROR:     { label: '退款失败', value: 12, cssClass: 'danger', show: true },
  PROVIDER_IN_APPEAL: { label: '申诉中', value: 13, cssClass: 'warn', show: true }, // 服务商已申诉
  APPEAL_FAILED:      { label: '申诉失败', value: 14, cssClass: 'danger', show: true },
}

/**
 * 服务商，订单状态
 */
export const SERVICE_ORDER_STATE = {
  CANCELED:           { label: '已取消', value: -1, cssClass: 'info', show: true },
  WAITING_PAY:        { label: '待支付', value: 1, cssClass: 'warn', show: true },
  WAITING_WORKING:    { label: '待工作', value: 2, cssClass: 'warn', show: true },
  WORKING:            { label: '工作中', value: 3, cssClass: 'success', show: true },
  WAITING_CHECK:      { label: '待验收', value: 4, cssClass: 'warn', show: true },
  CHECK_PASS:         { label: '待评价', value: 5, cssClass: 'warn', show: true },
  EVALUATED:          { label: '已评价', value: 6, cssClass: 'success', show: true },
  IN_APPEAL:          { label: '申诉中', value: 7, cssClass: 'warn', show: true },
  APPEAL_SUCCEED:     { label: '申诉失败', value: 8, cssClass: 'danger', show: true }, // 申诉成功、失败，是对于下单用户，相对的服务商申诉状态相反
  EXPIRED:            { label: '已超时', value: 9, cssClass: 'danger', show: false },
  REFUNDING:          { label: '退款中', value: 10, cssClass: 'success', show: true },
  REFUNDED:           { label: '已退款', value: 11, cssClass: 'success', show: true },
  REFUNDED_ERROR:     { label: '退款失败', value: 12, cssClass: 'danger', show: true },
  PROVIDER_IN_APPEAL: { label: '申诉中', value: 13, cssClass: 'warn', show: true }, // 服务商已申诉
  APPEAL_FAILED:      { label: '申诉成功', value: 14, cssClass: 'success', show: true },
}
