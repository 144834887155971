<template>
  <div class="orders">
    <div class="banner">
      <div class="Tabs">
        <div class="Tabs-item" :class="TabsIndex==0?'Tabs-item-active':''" @click="TabsIndex=0">订单详情</div>
        <!-- <div class="Tabs-item" :class="TabsIndex==1?'Tabs-item-active':''" @click="TabsIndex=1">订单进展</div> -->
      </div>
      <!-- 中 -->
      <div class="ord_con">
        <!-- 左侧详情进展 -->
        <div class="ord_lft bor_back" v-if="TabsIndex === 0">
          <div class="ord_ava ava_mes" @click="toServiceProviderHome">
            <div class="ord_ava">
              <el-avatar :size="55" :src="orderUser.avatarImg"></el-avatar>
              <span style="margin-left: 15px">{{ orderUser.nickName }}</span>
            </div>
            <div>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="money">
            <span>服务总价：<span class="jine">￥{{ orderInfo.orderAmount }}</span></span>
          </div>
          <div class="money">
            <span>实付金额：<span class="jine">￥{{ orderInfo.orderAmount }}</span></span>
          </div>
        </div>
        <div class="ord_lft bor_back" v-else>
          <div class="pay_info" v-for="(item,index) in stateList" :key="index">
            <div class="pay_mess">
              <i></i>
              <span>超时未支付，订单已取消</span>
              <span class="time_mes">{{ formatDate(item.createTime) }}</span>
            </div>
            <div>已取消</div>
          </div>
        </div>
        
        <!-- 右侧信息 -->
        <div class="ord_rgt">
          <div class="ord_ava ava_mess bor_back">
            <div class="ord_ava">
              <el-avatar :size="36" :src="orderUser.avatarImg"></el-avatar>
              <span style="margin-left: 15px">{{ orderUser.nickName }}</span>
            </div>
            <div style="color: #3d8bff; cursor: pointer" @click="clickShowChat">发消息</div>
          </div>
          <div class="ord_course bor_back">
            <div class="course_head">订单进展</div>
            <!-- <div style="height: 300px; margin-top: 30px">
              <el-steps direction="vertical" :active="1" finish-status="success">
                <el-step v-for="(item, index) in OrderInfo.order_statelist" :key="index" :title="item.title" :description="item.content"></el-step>
              </el-steps>
            </div>-->
            
            <div class="steps">
              <div class="steps-item" v-for="(item,index) in stateList" :key="index">
                <div class="item-flex">
                  <div class="item-type"></div>
                  <div class="item-center">
                    <div class="item-txt">{{ item.state }}</div>
                    <div class="day">{{ formatDate(item.createTime) }}</div>
                    <div class="remark" v-if="item.remark">{{ item.remark }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ord_course bor_back">
            <div class="course_head">平台支持</div>
            <div class="course_content">
              若在订单履约的过程中遇到任何问题，可联系平台，
              我们会第一时间为双方提供协助。
            </div>
            <div class="foot">联系平台</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { ORDER_STATE, SERVICE_ORDER_STATE } from "@/constants/order";

export default {
  data() {
    return {
      TabsIndex: 0,
      action:    [
        {
          title:   "支付",
          content: "资金支付至平台，订单开启前可随时全额退款",
        },
        {
          title:   "开启服务",
          content: "服务商上传需求共识，雇用方确认后开启订单",
        },
        {
          title:   "履约中",
          content: "依据双方的共识内容，完成履约",
        },
        {
          title:   "交付与验收",
          content: "雇用方确认验收无误，资金转至服务商账户",
        },
        {
          title:   "评价",
          content: "双方互评并展示在主页上",
        },
      ],
      // 订单信息
      orderInfo: "",
    };
  },
  created() {
    this.GetOrderDetails();
  },
  computed: {
    stateList() {
      let list = this.orderInfo.stateList || []
      let state = this.orderUser.id === this.$store.getters.getUser.id ? ORDER_STATE : SERVICE_ORDER_STATE
      list.forEach(item => {
        let key = Object.keys(state).find(key => state[key].value === item.orderState)
        if (key) {
          item.state = state[key].label
          item.stateClass = state[key].cssClass
          if (state[key].value === state.IN_APPEAL.value) item.state = '客户申诉中'
          else if (state[key].value === state.IN_APPEAL.value) item.state = '服务商申诉中'
        }
      })
      return list
    },
    orderUser() {
      return this.orderInfo.orderUser || {}
    }
  },
  methods:  {
    ...mapActions(['showChat']),
    // 订单详情 信息
    GetOrderDetails() {
      let url = "/app-api/hiring/service-order/get";
      this.$axios
          .get(url, {
            params: {
              id: this.$route.query.id
            }
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.orderInfo = res.data.data;
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    toServiceProviderHome() {
      this.$router.push({
        path:  "/Service/Home",
        query: { id: this.orderUser.id },
      });
    },
    clickShowChat() {
      if (!this.$store.getters.getUser || !this.$store.getters.getUser.id) {
        this.$notify.warning('您还未登录，请先登录')
        return
      }
      if (this.orderUser.userPhone === this.$store.getters.getUser.userPhone) {
        this.$notify.info('您不能向自己发消息！')
        return
      }
      this.showChat({
        show:  true,
        phone: this.orderUser.userPhone,
        goods: {
          id:   this.orderInfo.serviceId,
          name: this.orderInfo.serviceTitle,
          desc: this.orderInfo.serviceDescribe,
          img:  this.orderInfo.serviceImg
        }
      });
    },
    state() {
      return this.orderUser.id === this.$store.getters.getUser.id ? ORDER_STATE : SERVICE_ORDER_STATE
    }
  },
};
</script>
<style scoped lang="less">
.Tabs {
  width: 100%;
  border-radius: 8px;
  height: 88px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  
  .Tabs-item {
    color: #999999;
    font-size: 20px;
    margin-right: 30px;
    position: relative;
    cursor: pointer;
  }
  
  .Tabs-item-active {
    color: #f41e1e;
    
    &::after {
      content: "";
      position: absolute;
      bottom: -12px;
      left: 0;
      width: 100%;
      height: 2px;
      background: #f41e1e;
      border-radius: 2px;
    }
  }
}

.orders {
  background: #f7f9fa;
  padding: 30px 0;
  
  .orders_head {
    .heads {
      color: #999999;
      font-size: 24px;
      margin-right: 40px;
      cursor: pointer;
    }
    
    .active {
      color: #fc3737;
      text-decoration: underline;
    }
  }
  
  .bor_back {
    background: #ffffff;
    box-shadow: 0px 4px 4px 1px rgba(204, 226, 237, 0.5);
    border-radius: 8px 8px 8px 8px;
  }
  
  .ord_con {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    // 左侧信息
    .ord_lft {
      width: 1021px;
      padding: 30px;
      height: 100%;
      padding-right: 44px;
      box-sizing: border-box;
      // 头像信息
      .ava_mes {
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #d9d9d9;
        padding: 15px 12px;
      }
      
      // 价格
      .money {
        font-size: 15px;
        margin-top: 20px;
        color: #999999;
        
        .jine {
          font-weight: bold;
          color: #111;
        }
      }
      
      // 订单进展
      .pay_info {
        display: flex;
        justify-content: space-between;
        height: 53px;
        line-height: 53px;
        border-bottom: 1px solid #d9d9d9;
        
        &:last-of-type {
          border-bottom: none !important;
        }
        
        .pay_mess {
          .time_mes {
            margin-left: 20px;
          }
        }
      }
    }
    
    .ord_ava {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    .ord_rgt {
      width: 400px;
      
      .ava_mess {
        padding: 18px;
        font-size: 15px;
      }
      
      .ord_course {
        margin-top: 20px;
        padding: 20px 17px;
        font-size: 16px;
        
        .course_content {
          margin-top: 10px;
          color: #999999;
        }
        
        .foot {
          margin-top: 10px;
          cursor: pointer;
          color: #3d8bff;
        }
      }
      
      .course_head {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}

// 订单进展
.steps {
  .steps-item {
    margin: 14px 0;
    position: relative;
    
    &:last-of-type {
      position: inherit !important;
      
      .item-txt {
        color: #000000 !important;
      }
      
      .item-type {
        border: 1px solid #209f85 !important;
        background: #ffffff !important;
        
        &::after {
          position: absolute;
          content: "";
          width: 6px;
          height: 6px;
          background: #209f85;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          border-radius: 50%;
        }
      }
    }
    
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 100%;
      left: 6px;
      background: #d9d9d9;
      top: 19px;
    }
    
    .remark {
      margin-top: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 16px;
      font-size: 14px;
    }
    
    .item-center {
      margin-left: 20px;
      div + div {
        margin-top: 6px;
      }
    }
    
    .item-flex {
      display: flex;
      
      .item-type {
        width: 12px;
        height: 12px;
        margin-top: 5px;
        // border: 1px solid #209f85;
        background: #d9d9d9;
        border: 1px solid #999999;
        position: relative;
        border-radius: 50%;
      }
      
      .item-txt {
        font-weight: 400;
        color: #999999;
        // line-height: 19px;
        font-size: 16px;
        
      }
      
      .day {
        font-weight: 400;
        color: #999999;
        line-height: 16px;
        font-size: 13px;
      }
    }
  }
}
</style>
